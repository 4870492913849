document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("show-checkout-info")?.addEventListener("click", () => {
    document.querySelector(".checkout-info-modal")?.classList.remove("hidden")
  })

  document.querySelector("#checkout-modal .dialog-wrapper")?.addEventListener("click", () => {
    document.querySelector(".checkout-info-modal")?.classList.add("hidden")
  })

  document.querySelector("#checkout-modal .close-btn")?.addEventListener("click", () => {
    document.querySelector(".checkout-info-modal")?.classList.add("hidden")
  })
})
